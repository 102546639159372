import { encryptedStorage } from './EncryptedStorage';

const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const TOKEN_EXPIRY_KEY = 'token_expiry';
const REFRESH_TOKEN_EXPIRY_KEY = 'refresh_token_expiry';
const API_KEY = 'OdNHsmrg3aUTCjssYpMTFI7sd/Zfl7noquLtVxvkM0w='; // Add this line

export const setTokens = async (accessToken, refreshToken) => {
  const now = new Date();
  const accessTokenExpiry = new Date(now.getTime() + 60 * 60 * 1000); // 1 hour from now
  const refreshTokenExpiry = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 days from now

  await encryptedStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  await encryptedStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  await encryptedStorage.setItem(TOKEN_EXPIRY_KEY, accessTokenExpiry.toISOString());
  await encryptedStorage.setItem(REFRESH_TOKEN_EXPIRY_KEY, refreshTokenExpiry.toISOString());
};

export const getAccessToken = async () => {
  const token = await encryptedStorage.getItem(ACCESS_TOKEN_KEY);
  const expiry = await encryptedStorage.getItem(TOKEN_EXPIRY_KEY);

  if (!token || !expiry) {
    return null;
  }

  if (new Date(expiry) <= new Date()) {
    return refreshAccessToken();
  }

  return token;
};

export const refreshAccessToken = async () => {
  const refreshToken = await encryptedStorage.getItem(REFRESH_TOKEN_KEY);
  const refreshExpiry = await encryptedStorage.getItem(REFRESH_TOKEN_EXPIRY_KEY);

  if (!refreshToken || new Date(refreshExpiry) <= new Date()) {
    // Refresh token has expired, user needs to login again
    return null;
  }

  try {
    const response = await axios.post('https://dev.corgi.vet/refresh/', {
      refresh_token: refreshToken,
      device_idfv: navigator.userAgent
    }, {
      headers: {
        'X-API-Key': API_KEY // Add the API key to the headers
      }
    });

    const { access_token } = response.data;

    if (access_token) {
      await setTokens(access_token, refreshToken); // Update access token and its expiry
      return access_token;
    }
  } catch (error) {
    console.error('Failed to refresh access token:', error);
  }

  return null;
};

export const logout = async () => {
  await encryptedStorage.removeItem(ACCESS_TOKEN_KEY);
  await encryptedStorage.removeItem(REFRESH_TOKEN_KEY);
  await encryptedStorage.removeItem(TOKEN_EXPIRY_KEY);
  await encryptedStorage.removeItem(REFRESH_TOKEN_EXPIRY_KEY);
};