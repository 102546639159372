import CryptoJS from 'crypto-js';

const SECRET_KEY = 'your-secret-key'; // Store this securely, possibly in an environment variable

export const encryptedStorage = {
  setItem: async (key, value) => {
    const encryptedValue = CryptoJS.AES.encrypt(value, SECRET_KEY).toString();
    localStorage.setItem(key, encryptedValue);
  },

  getItem: async (key) => {
    const encryptedValue = localStorage.getItem(key);
    if (encryptedValue) {
      const decryptedValue = CryptoJS.AES.decrypt(encryptedValue, SECRET_KEY).toString(CryptoJS.enc.Utf8);
      return decryptedValue;
    }
    return null;
  },

  removeItem: async (key) => {
    localStorage.removeItem(key);
  }
};