import React, { useState, useEffect, useRef } from 'react';
import { View, Text, Image, ScrollView, StyleSheet, TouchableOpacity, PanResponder } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import tw from 'twrnc';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import dayjs from 'dayjs';
import { refreshAccessToken } from './TokenManager'; // Import the token refresh function

const API_KEY = 'OdNHsmrg3aUTCjssYpMTFI7sd/Zfl7noquLtVxvkM0w=';

const HomeScreen = ({ userEmail, accountId, onLogout, colorScheme }) => {
  // ... (existing state variables)
  const isDarkMode = colorScheme === 'dark';
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [allDocuments, setAllDocuments] = useState([]);
  const [soapDocuments, setSoapDocuments] = useState([]);
  const [templateDocuments, setTemplateDocuments] = useState([]);
  const [dischargeDocuments, setDischargeDocuments] = useState([]);
  const [otherDocuments, setOtherDocuments] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [selectedButton, setSelectedButton] = useState(null); // No default selection
  const [leftWidth, setLeftWidth] = useState('20%');
  const [rightWidth, setRightWidth] = useState('80%');
  const dividerRef = useRef(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchAllDocuments(1);
  }, []);

  useEffect(() => {
    loadDocuments(selectedButton);
  }, [allDocuments, soapDocuments, templateDocuments, dischargeDocuments, otherDocuments, selectedButton, currentPage]);

  const fetchAllDocuments = async (page) => {
    await fetchDocuments(page);
    await fetchTemplateDocuments();
  };

  const apiClient = axios.create({
    baseURL: 'https://dev.corgi.vet',
  });

  // Add a request interceptor
  apiClient.interceptors.request.use(async (config) => {
    let token = await AsyncStorage.getItem('token');
    const expiry = await AsyncStorage.getItem('token_expiry');

    if (expiry && new Date(expiry) <= new Date()) {
      // Token is expired, try to refresh
      const newToken = await refreshAccessToken();
      if (newToken) {
        token = newToken;
      } else {
        // If refresh failed, logout the user
        onLogout();
        return Promise.reject('Session expired');
      }
    }

    config.headers['Authorization'] = `Bearer ${token}`;
    config.headers['X-API-Key'] = API_KEY;
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  // Add a response interceptor
  apiClient.interceptors.response.use((response) => {
    return response;
  }, async (error) => {
    if (error.response && error.response.status === 401) {
      // Try to refresh the token
      const newToken = await refreshAccessToken();
      if (newToken) {
        // Retry the original request
        const originalRequest = error.config;
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        originalRequest.headers['X-API-Key'] = API_KEY;
        return apiClient(originalRequest);
      } else {
        // If refresh failed, logout the user
        onLogout();
      }
    }
    return Promise.reject(error);
  });

  const fetchDocuments = async (page) => {
    try {
      console.log('Fetching documents:', { page, per_page: 20 });
      const response = await apiClient.post('/documents/', {
        page: page,
        per_page: 20,
      });
      console.log('Response:', response.data);
      const documents = response.data.documents;
      setAllDocuments(documents);
      setSoapDocuments(documents.filter(doc => doc.document_type === 'SOAP'));
      setDischargeDocuments(documents.filter(doc => doc.document_type === 'DischargeNote'));
      setOtherDocuments(documents.filter(doc => !doc.document_type || doc.document_type === "VoiceMemo"));
      setTotalPages(response.data.total_pages);
      setCurrentPage(page);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

  const fetchTemplateDocuments = async () => {
    try {
      const response = await apiClient.get('/scribe_custom_templates/');
      setTemplateDocuments(response.data);
    } catch (error) {
      console.error('Error fetching AI Scribe Templates:', error);
    }
  };

  const loadDocuments = (documentType) => {
    console.log('Loading documents for:', documentType);
    setSelectedButton(documentType);
    if (documentType === 'SOAP') {
      setDocuments(soapDocuments);
    } else if (documentType === 'AI Scribe Templates') {
      setDocuments(templateDocuments);
    } else if (documentType === 'Discharge Notes') {
      setDocuments(dischargeDocuments);
    } else if (documentType === 'Other') {
      setDocuments(otherDocuments);
    } else {
      setDocuments(allDocuments); // Load all documents if no type is selected
    }
    setSelectedDocument(null);
  };
  
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      fetchDocuments(currentPage - 1);
    }
  };
  
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      fetchDocuments(currentPage + 1);
    }
  };
  
  const handlePageClick = (page) => {
    fetchDocuments(page);
  };
  
  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <TouchableOpacity key={i} onPress={() => handlePageClick(i)} style={tw`mx-1`}>
          <Text style={[tw`text-lg`, currentPage === i ? styles.selectedPage : styles.pageNumber]}>{i}</Text>
        </TouchableOpacity>
      );
    }
    return pageNumbers;
  };
  
  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onPanResponderMove: (evt, gestureState) => {
        const totalWidth = dividerRef.current.getBoundingClientRect().width;
        const newLeftWidth = (gestureState.moveX / totalWidth) * 100;
        setLeftWidth(`${newLeftWidth}%`);
        setRightWidth(`${100 - newLeftWidth}%`);
      },
    })
  ).current;
  
  const formatDate = (dateString) => {
    return dayjs(dateString).format('MMMM D, YYYY');
  };
  
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };
  
  return (
    <View style={[tw`flex-1`, isDarkMode ? styles.darkContainer : styles.lightContainer]}>
      <View style={[tw`flex-row justify-between items-center p-3`, styles.stickyHeader]}>
        <View style={tw`flex-row items-center`}>
          <Image
            source={isDarkMode ? require('../../assets/Corgi-Wordmark-White.png') : require('../../assets/Corgi-Wordmark-Black.png')}
            style={tw`h-7 w-15`}
            resizeMode="contain"
          />
          <Text style={[tw`ml-4 text-base font-bold`, isDarkMode ? styles.darkText : styles.lightText, styles.customFont]}>Documents</Text>
        </View>
        <View style={tw`flex-row items-center`}>
          <Text style={[tw`mr-4 text-base`, isDarkMode ? styles.darkText : styles.lightText, styles.customFont]}>{userEmail}</Text>
          <TouchableOpacity style={[tw`p-1 rounded`, styles.logoutButton]} onPress={onLogout}>
            <Text style={tw`text-sm`, styles.logoutButtonText}>Logout</Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={[tw`h-px`, isDarkMode ? styles.darkDivider : styles.lightDivider]}></View>
      <View style={[tw`flex-row justify-between p-2`, styles.stickyHeader]}>
        <DocumentButton title="SOAP" colorScheme={colorScheme} onPress={() => loadDocuments(selectedButton === 'SOAP' ? null : 'SOAP')} selected={selectedButton === 'SOAP'} />
        <DocumentButton title="Discharge Notes" colorScheme={colorScheme} onPress={() => loadDocuments(selectedButton === 'Discharge Notes' ? null : 'Discharge Notes')} selected={selectedButton === 'Discharge Notes'} />
        <DocumentButton title="Other" colorScheme={colorScheme} onPress={() => loadDocuments(selectedButton === 'Other' ? null : 'Other')} selected={selectedButton === 'Other'} />
        <DocumentButton title="AI Scribe Templates" colorScheme={colorScheme} onPress={() => loadDocuments(selectedButton === 'AI Scribe Templates' ? null : 'AI Scribe Templates')} selected={selectedButton === 'AI Scribe Templates'} />
      </View>
      <View style={[tw`h-px`, isDarkMode ? styles.darkDivider : styles.lightDivider]}></View>
      
      <View style={tw`flex-1 flex-row`} ref={dividerRef}>
        <ScrollView style={{ width: leftWidth, height: '85vh', padding: 20 }} contentContainerStyle={tw`flex-grow`}>
          {documents.map((doc, index) => (
            <TouchableOpacity key={index} onPress={() => setSelectedDocument(doc)}>
              <View style={[tw`mb-3 p-2 rounded-lg`, selectedDocument?.id === doc.id ? styles.selectedDocument : null]}>
                {selectedButton === 'AI Scribe Templates' ? (
                  <Text style={[tw`text-base font-bold`, isDarkMode ? styles.darkText : styles.lightText, styles.customFont, selectedDocument === doc ? styles.selectedDocumentText : null]}>{doc.template_type}</Text>
                ) : (
                  <>
                    <Text style={[tw`text-base font-bold`, isDarkMode ? styles.darkText : styles.lightText, styles.customFont, selectedDocument?.id === doc.id ? styles.selectedDocumentText : null]}>{doc.title}</Text>
                    {doc.patient_id && <Text style={[tw`text-sm`, isDarkMode ? styles.darkText : styles.lightText, styles.customFont, selectedDocument?.id === doc.id ? styles.selectedDocumentText : null]}>Patient {doc.patient_id}</Text>}
                    <Text style={[tw`text-sm`, isDarkMode ? styles.darkText : styles.lightText, styles.customFont, selectedDocument?.id === doc.id ? styles.selectedDocumentText : null]}>{formatDate(doc.created_at_device)}</Text>
                  </>
                )}
              </View>
            </TouchableOpacity>
          ))}
          <View style={tw`flex-row justify-center items-center mt-3`}>
            <TouchableOpacity onPress={handlePreviousPage} disabled={currentPage === 1} style={tw`mx-2`}>
              <Ionicons name="chevron-back" size={24} color={currentPage === 1 ? '#aaa' : isDarkMode ? '#fff' : '#000'} />
            </TouchableOpacity>
            {renderPageNumbers()}
            <TouchableOpacity onPress={handleNextPage} disabled={currentPage === totalPages} style={tw`mx-2`}>
              <Ionicons name="chevron-forward" size={24} color={currentPage === totalPages ? '#aaa' : isDarkMode ? '#fff' : '#000'} />
            </TouchableOpacity>
          </View>
        </ScrollView>
        
        <View style={[styles.rightContentContainer, { width: rightWidth }]}>
          <ScrollView contentContainerStyle={tw`p-5`}>
            {selectedDocument ? (
              <View>
                <View style={[tw`mb-4 p-4 rounded-lg`, isDarkMode ? styles.headerDark : styles.headerLight]}>
                  <Text style={[tw`text-2xl font-bold mb-2`, isDarkMode ? styles.darkText : styles.lightText, styles.customFont]}>{selectedButton === 'AI Scribe Templates' ? selectedDocument.template_type : selectedDocument.title}</Text>
                  {selectedButton !== 'AI Scribe Templates' && <Text style={[tw`text-sm`, isDarkMode ? styles.darkText : styles.lightText, styles.customFont]}>{formatDate(selectedDocument.created_at_device)}</Text>}
                </View>
                {selectedButton === 'AI Scribe Templates' ? (
                  <View style={[tw`mb-4 p-4 rounded-lg relative`, isDarkMode ? styles.contentDark : styles.contentLight]}>
                    <Text style={[tw`text-sm`, isDarkMode ? styles.darkText : styles.lightText, styles.customFont, styles.sectionContent]}>
                      {selectedDocument.template}
                    </Text>
                  </View>
                ) : (
                  <>
                    {selectedDocument.document_type === 'SOAP' && (
                      <>
                        <View style={[tw`mb-4 p-4 rounded-lg relative`, isDarkMode ? styles.contentDark : styles.contentLight]}>
                          <TouchableOpacity style={styles.copyButton} onPress={() => copyToClipboard(selectedDocument.soap_user_edited)}>
                            <Text style={styles.copyButtonText}>Copy</Text>
                          </TouchableOpacity>
                          <Text style={[tw`text-base`, isDarkMode ? styles.darkText : styles.lightText, styles.customFont, styles.sectionTitle]}>SOAP</Text>
                          <Text style={[tw`text-sm`, isDarkMode ? styles.darkText : styles.lightText, styles.customFont, styles.sectionContent]}>{selectedDocument.soap_user_edited}</Text>
                        </View>
                        <View style={[tw`mb-4 p-4 rounded-lg relative`, isDarkMode ? styles.contentDark : styles.contentLight]}>
                          <TouchableOpacity style={styles.copyButton} onPress={() => copyToClipboard(selectedDocument.soap_transcription)}>
                            <Text style={styles.copyButtonText}>Copy</Text>
                          </TouchableOpacity>
                          <Text style={[tw`text-base`, isDarkMode ? styles.darkText : styles.lightText, styles.customFont, styles.sectionTitle]}>Transcription</Text>
                          <Text style={[tw`text-sm`, isDarkMode ? styles.darkText : styles.lightText, styles.customFont, styles.sectionContent]}>{selectedDocument.soap_transcription}</Text>
                        </View>
                      </>
                    )}
                    {selectedDocument.document_type === 'DischargeNote' && (
                      <>
                        <View style={[tw`mb-4 p-4 rounded-lg relative`, isDarkMode ? styles.contentDark : styles.contentLight]}>
                          <TouchableOpacity style={styles.copyButton} onPress={() => copyToClipboard(selectedDocument.discharge_user_edited)}>
                            <Text style={styles.copyButtonText}>Copy</Text>
                          </TouchableOpacity>
                          <Text style={[tw`text-base`, isDarkMode ? styles.darkText : styles.lightText, styles.customFont, styles.sectionTitle]}>Discharge Note</Text>
                          <Text style={[tw`text-sm`, isDarkMode ? styles.darkText : styles.lightText, styles.customFont, styles.sectionContent]}>{selectedDocument.discharge_user_edited}</Text>
                        </View>
                        <View style={[tw`mb-4 p-4 rounded-lg relative`, isDarkMode ? styles.contentDark : styles.contentLight]}>
                          <TouchableOpacity style={styles.copyButton} onPress={() => copyToClipboard(selectedDocument.discharge_input)}>
                            <Text style={styles.copyButtonText}>Copy</Text>
                          </TouchableOpacity>
                          <Text style={[tw`text-base`, isDarkMode ? styles.darkText : styles.lightText, styles.customFont, styles.sectionTitle]}>Discharge Input</Text>
                          <Text style={[tw`text-sm`, isDarkMode ? styles.darkText : styles.lightText, styles.customFont, styles.sectionContent]}>{selectedDocument.discharge_input}</Text>
                        </View>
                      </>
                    )}
                    {selectedDocument.document_type === null && (
                      <View style={[tw`mb-4 p-4 rounded-lg relative`, isDarkMode ? styles.contentDark : styles.contentLight]}>
                        <TouchableOpacity style={styles.copyButton} onPress={() => copyToClipboard(selectedDocument.content)}>
                          <Text style={styles.copyButtonText}>Copy</Text>
                        </TouchableOpacity>
                        <Text style={[tw`text-sm`, isDarkMode ? styles.darkText : styles.lightText, styles.customFont, styles.sectionContent]}>{selectedDocument.content}</Text>
                      </View>
                    )}
                  </>
                )}
              </View>
            ) : (
              <Text style={[tw`text-sm`, isDarkMode ? styles.darkText : styles.lightText, styles.customFont]}>Select a document to view its details.</Text>
            )}
          </ScrollView>
        </View>
      </View>
      
    </View>
  );
  };
  
  const DocumentButton = ({ title, colorScheme, onPress, selected }) => {
  const isDarkMode = colorScheme === 'dark';
  return (
    <TouchableOpacity style={[tw`flex-1 m-1 p-2 rounded-xl`, selected ? styles.selectedButton : (isDarkMode ? styles.darkButton : styles.lightButton)]} onPress={onPress}>
      <Text style={[tw`text-center text-base font-bold`, selected ? styles.selectedButtonText : (isDarkMode ? styles.darkButtonText : styles.lightButtonText), styles.customFont]}>{title}</Text>
    </TouchableOpacity>
  );  
};


const styles = StyleSheet.create({
  darkContainer: {
    backgroundColor: '#000',
  },
  lightContainer: {
    backgroundColor: '#fff',
  },
  extraDarkBackground: {
    backgroundColor: '#111',
  },
  extraLightBackground: {
    backgroundColor: '#f9f9f9',
  },
  darkText: {
    color: '#ddd',
  },
  lightText: {
    color: '#222',
  },
  darkHeading: {
    color: '#fff',
  },
  lightHeading: {
    color: '#000',
  },
  darkButton: {
    backgroundColor: '#333',
  },
  lightButton: {
    backgroundColor: '#eee',
  },
  darkButtonText: {
    color: '#fff',
  },
  lightButtonText: {
    color: '#000',
  },
  selectedButton: {
    backgroundColor: '#2651E5',
  },
  selectedButtonText: {
    color: '#fff',
  },
  selectedDocument: {
    backgroundColor: '#2651E5',
  },
  selectedDocumentText: {
    color: '#fff',
  },
  darkDivider: {
    backgroundColor: '#555',
  },
  lightDivider: {
    backgroundColor: '#ccc',
  },
  customFont: {
    fontFamily: 'CustomFont',
  },
  divider: {
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'ew-resize',
    borderLeftWidth: 2,
    borderLeftColor: '#888', // Makes the divider more visible
  },
  dividerText: {
    fontSize: 20,
    color: '#888',
  },
  darkDividerBackground: {
    backgroundColor: '#555',
  },
  lightDividerBackground: {
    backgroundColor: '#ccc',
  },
  headerDark: {
    backgroundColor: '#222',
  },
  headerLight: {
    backgroundColor: '#fff',
    borderColor: '#ccc',
    borderWidth: 1,
  },
  contentDark: {
    backgroundColor: '#222',
  },
  contentLight: {
    backgroundColor: '#fff',
    borderColor: '#ccc',
    borderWidth: 1,
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
    backgroundColor: 'inherit',
  },
  rightContentContainer: {
    height: '85vh',
    overflowY: 'scroll',
  },
  copyButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    backgroundColor: '#2651E5',
    borderRadius: 4,
    paddingVertical: 8,
    paddingHorizontal: 12,
    zIndex: 1,
  },
  copyButtonText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 14,
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: 10,
    textTransform: 'uppercase',
  },
  sectionDivider: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    marginVertical: 10,
  },
  sectionContent: {
    fontSize: 14,
    lineHeight: 20,
  },
  logoutButton: {
    backgroundColor: '#2651E5',
    padding: 8,
  },
  logoutButtonText: {
    color: '#fff',
    textTransform: 'none',
  },
  pageNumber: {
    color: '#2651E5',
  },
  selectedPage: {
    color: '#fff',
    backgroundColor: '#2651E5',
    borderRadius: 5,
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
});

export default HomeScreen;
