import React, { useEffect, useState, useCallback } from 'react';
import { View, StyleSheet, useColorScheme } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import LoginScreen from './src/components/LoginScreen';
import HomeScreen from './src/components/HomeScreen';
import * as Font from 'expo-font';

const loadFonts = () => {
  return Font.loadAsync({
    'CustomFont': require('./assets/PlusJakartaSans-VariableFont_wght.ttf'),
  });
};

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [accountId, setAccountId] = useState(null);
  const colorScheme = useColorScheme();

  useEffect(() => {
    async function prepare() {
      try {
        // Load fonts
        await loadFonts();
        
        // Check auth status
        const token = await AsyncStorage.getItem('token');
        const storedEmail = await AsyncStorage.getItem('userEmail');
        const storedAccountId = await AsyncStorage.getItem('account_id');
        if (token && storedEmail && storedAccountId) {
          setUserEmail(storedEmail);
          setAccountId(storedAccountId);
          setIsLoggedIn(true);
        }
      } catch (e) {
        console.warn(e);
      } finally {
        // Tell the application to render
        setFontsLoaded(true);
      }
    }

    prepare();
  }, []);

  const onLayoutRootView = useCallback(async () => {
    
  }, [fontsLoaded]);

  const handleLogin = async (email, token, accountId) => {
    await AsyncStorage.setItem('token', token);
    await AsyncStorage.setItem('userEmail', email);
    await AsyncStorage.setItem('account_id', accountId.toString());
    setUserEmail(email);
    setAccountId(accountId);
    setIsLoggedIn(true);
  };

  const handleLogout = async () => {
    await AsyncStorage.removeItem('token');
    await AsyncStorage.removeItem('userEmail');
    await AsyncStorage.removeItem('account_id');
    setUserEmail('');
    setAccountId(null);
    setIsLoggedIn(false);
  };

  if (!fontsLoaded) {
    return null;
  }

  return (
    <View 
      style={[
        styles.container, 
        colorScheme === 'dark' ? styles.darkContainer : styles.lightContainer
      ]}
      onLayout={onLayoutRootView}
    >
      {isLoggedIn ? (
        <HomeScreen userEmail={userEmail} accountId={accountId} onLogout={handleLogout} colorScheme={colorScheme} />
      ) : (
        <LoginScreen onLogin={handleLogin} colorScheme={colorScheme} />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    minWidth: 1000,
    minHeight: 500,
  },
  darkContainer: {
    backgroundColor: '#000',
  },
  lightContainer: {
    backgroundColor: '#fff',
  },
});