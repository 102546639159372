import React, { useState, useRef } from 'react';
import { View, TextInput, Button, Alert, StyleSheet, Text, Image, TouchableOpacity, Animated } from 'react-native';
import tw from 'twrnc';
import axios from 'axios';
import { setTokens } from './TokenManager';

const API_KEY = 'OdNHsmrg3aUTCjssYpMTFI7sd/Zfl7noquLtVxvkM0w=';

const LoginScreen = ({ onLogin, colorScheme }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const isDarkMode = colorScheme === 'dark';
  const fadeAnim = useRef(new Animated.Value(1)).current;

  const getDeviceInfo = () => {
    // In a web context, we'll use available browser information
    return {
      device_name: navigator.platform || 'Web Browser',
      device_idfv: navigator.userAgent,
      device_token: localStorage.getItem('deviceToken') || '',
      fcm_token: localStorage.getItem('fcmToken') || '',
    };
  };

  const handleLogin = async () => {
    try {
      const deviceInfo = getDeviceInfo();
      const url = 'https://dev.corgi.vet/login/';

      const body = {
        email,
        password,
        ...deviceInfo,
      };

      console.log(`*** Attempting to login with ${email} // ${password} // device ${deviceInfo.device_name} idfv ${deviceInfo.device_idfv}`);

      const response = await axios.post(url, body, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-API-Key': API_KEY
        },
      });

      const loginResponse = response.data;

      if (!loginResponse) {
        console.log("*** LOGIN - no login data provided");
        setErrorMessage("No login data provided");
        return;
      }

      if (!loginResponse.access_token) {
        console.log("*** LOGIN - could not get access token");
        setErrorMessage("Invalid credentials");
        return;
      }

      if (!loginResponse.refresh_token) {
        console.log("*** LOGIN - could not get refresh token");
        setErrorMessage("Invalid credentials");
        return;
      }

      if (!loginResponse.account_id) {
        console.log("*** LOGIN - could not get account id");
        setErrorMessage("Invalid credentials");
        return;
      }

      // Store tokens securely
      await setTokens(loginResponse.access_token, loginResponse.refresh_token);

      // Start fade out animation
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }).start(async () => {
        await onLogin(email, loginResponse.access_token, loginResponse.account_id);
      });

    } catch (error) {
      console.log("*** LOGIN - error:", error.response?.data || error.message);
      setErrorMessage(error.response?.data?.message || 'Login failed. Please try again.');
    }
  };

  return (
    <View style={[tw`flex-1 justify-center items-center p-5`, isDarkMode ? styles.darkContainer : styles.lightContainer]}>
      <Animated.View style={[styles.box, isDarkMode ? styles.darkBoxShadow : styles.lightBoxShadow, { opacity: fadeAnim }]}>
        <Image
          source={isDarkMode ? require('../../assets/Corgi-Wordmark-White.png') : require('../../assets/Corgi-Wordmark-Black.png')}
          style={tw`h-10 w-22 mb-5`}
          resizeMode="contain"
        />
        <TextInput
          style={[tw`h-10 border mb-3 px-3 rounded w-full`, isDarkMode ? styles.darkInput : styles.lightInput, styles.customFont]}
          placeholder="Email"
          value={email}
          onChangeText={setEmail}
          placeholderTextColor={isDarkMode ? '#ccc' : '#888'}
        />
        <TextInput
          style={[tw`h-10 border mb-3 px-3 rounded w-full`, isDarkMode ? styles.darkInput : styles.lightInput, styles.customFont]}
          placeholder="Password"
          value={password}
          onChangeText={setPassword}
          secureTextEntry
          placeholderTextColor={isDarkMode ? '#ccc' : '#888'}
        />
        {errorMessage ? <Text style={[tw`mb-3`, isDarkMode ? styles.darkErrorText : styles.lightErrorText]}>{errorMessage}</Text> : null}
        <TouchableOpacity style={tw`rounded-xl overflow-hidden w-full`} onPress={handleLogin}>
          <Text style={tw`text-center p-3 text-lg text-white bg-[#2651E5] capitalize`}>Login</Text>
        </TouchableOpacity>
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  darkContainer: {
    backgroundColor: '#000',
  },
  lightContainer: {
    backgroundColor: '#fff',
  },
  darkInput: {
    borderColor: '#444',
    color: '#fff',
  },
  lightInput: {
    borderColor: '#ccc',
    color: '#000',
  },
  darkErrorText: {
    color: '#ff3333',
  },
  lightErrorText: {
    color: '#cc0000',
  },
  customFont: {
    fontFamily: 'CustomFont',
  },
  box: {
    padding: 30,  // Added more padding
    borderRadius: 10,
    width: '50%',
    minWidth: 300,
    alignItems: 'center',
  },
  darkBoxShadow: {
    shadowColor: '#222',
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.9,
    shadowRadius: 10,
    elevation: 10,
  },
  lightBoxShadow: {
    shadowColor: '#999',
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.9,
    shadowRadius: 10,
    elevation: 10,
  },
});

export default LoginScreen;
